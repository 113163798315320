import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './BaseLink.module.css';

const BaseLink = React.forwardRef(({ className, children, to, testId, ...rest }, ref) => {
  const classnames = [
    styles.root,
    className,
  ];

  return to ? (
    <Link ref={ref} className={cn(classnames)} to={to} data-testid={testId} {...rest}>
      {children}
    </Link>
  ) : (
    <a ref={ref} className={cn(classnames)} data-testid={testId} {...rest}>
      {children}
    </a>
  );
});

export default BaseLink;
