import React from 'react';
import * as actionCreators from '../store/actions';
import moment from 'moment-timezone';
import Constants from './constants';
import { themes } from '../components/reusable/themes/styleAttributes/colors';

export function now() {
  return moment().format('YYYY-MM-DD');
}

export const isObjectEmpty = (object) => {
  return (
    object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype
  );
};

export function cutString(str, len = 25) {
  if (!!str) {
    if (str.length <= len) {
      return str;
    }

    return str.substr(0, len - 2) + '...';
  }
}

export function shouldBreakLine(str, len = 25) {
  if (str.split(' ').some((el) => el.length >= len)) {
    return { lineBreak: 'anywhere' };
  } else {
    return {};
  }
}

export function truncateNum(num, firstNonZero = true, precision = 5) {
  if (firstNonZero) {
    const txtNum = num + '';
    const split = txtNum.split('.');
    if (split.length === 2) {
      precision = split[1].split('').findIndex((c) => c !== '0') + 2;
    }
  }
  const mul = Math.pow(10, precision);
  return Math.floor(num * mul) / mul;
}

export function leadZeros(num, withPlus = false, resultLength = 2) {
  const strNum = `${Math.abs(num)}`;
  const len = strNum.length;
  const sign = num < 0 ? '-' : withPlus ? '+' : '';
  return `${sign}${'0'.repeat(Math.max(0, resultLength - len))}${strNum}`;
}

export function getTzRepresentation(tz) {
  const offset = moment().tz(tz).format('Z');
  return `GMT ${offset} ${tz}`;
}

export function capitalizeFirstLetter(str) {
  return `${str[0].toUpperCase()}${str.substr(1)}`;
}

export const getBreakpoint = (width) => {
  let breakpoint = 'full';
  if (width > 991 && width <= 1400) {
    breakpoint = 'large';
  }
  if (width > 767 && width <= 991) {
    breakpoint = 'medium';
  }
  if (width <= 767) {
    breakpoint = 'small';
  }

  return breakpoint;
};

function handleErrorStatus(dispatch, status) {
  if (status === 401) {
    dispatch(actionCreators.logOut());
  }
  dispatch(actionCreators.setSpecialError(status));
}

function handleErrorData(data, fieldsToSearch, validatorRef) {
  const resultError = {};
  for (let errorKey in data) {
    const errorsForField = data[errorKey];
    if (errorKey === 'error_details') {
      resultError.errorInfo = errorsForField
    } else {
      const newText = Array.isArray(errorsForField) ? errorsForField.join('\n') : errorsForField;
      if (fieldsToSearch.includes(errorKey)) {
        resultError[errorKey] = newText;
      } else {
        if (resultError.other) {
          resultError.other += `\n${newText}`;
        } else {
          resultError.other = newText;
        }
      }
    }
  }

  if (validatorRef && validatorRef.current) {
    const validatorMessages = Object.keys(resultError).map((fieldName) => ({
      name: fieldName,
      status: 'invalid',
      message: resultError[fieldName],
    }));
    validatorRef.current.setMsgAndStatuses(validatorMessages);
  }

  return resultError;
}

export function handleBackendError(
  translate,
  dispatch,
  errorRaw,
  fieldsToSearch = [],
  validatorRef,
  viaRedux = true
) {
  let errorParsed = false;

  if (errorRaw.response) {
    const { status, data } = errorRaw.response;

    if ((status && [401, 404, 500].includes(status)) || status > 500) {
      handleErrorStatus(dispatch, status);

      const specialError = { other: translate(`errors:got${status}`) };
      if (viaRedux) {
        dispatch(actionCreators.setError(specialError));
        errorParsed = true;
      } else {
        return specialError;
      }
    } else if (data) {
      const resultError = handleErrorData(data, fieldsToSearch, validatorRef);
      if (viaRedux) {
        errorParsed = true;
        dispatch(actionCreators.setError(resultError));
      } else {
        return resultError;
      }
    }
  }

  if (!errorParsed) {
    const unknownError = { other: translate('errors:unknownError') };
    if (viaRedux) {
      dispatch(actionCreators.setError(unknownError));
    } else {
      return unknownError;
    }
  }
}

export function getAqiColour(aqiType, aqiLevel) {
  if (!aqiType || !aqiLevel) {
    return themes.dark.AQIValueText;
  }
  const aqiLevels = Constants.aqiLevels[aqiType];
  const colourArrLength = aqiLevels.length;
  const idx = aqiLevels.findIndex((lvl) => lvl === aqiLevel);
  return Constants.aqiColours[colourArrLength][idx];
}

export function getEiaqiColour(level) {
  if (!level) {
    return themes.dark.AQIValueText;
  }
  const idx = Constants.eiaqiLevels.findIndex((lvl) => lvl === level);
  return Constants.aqiColours[Constants.eiaqiLevels.length][idx];
}

export const getFieldsForTable = (translate, fields) =>
  fields.map((item) => ({ ...item, label: translate(item.label) }));

export const arraySet = (array, setArray, name) => {
  if (array.includes(name)) {
    setArray(array.filter((item) => item !== name));
  } else {
    setArray([...array, name]);
  }
};

export const getDevicesNamesById = (ids, devices) => {
  const SelectedDevicesNames = [];
  ids.forEach((id) => {
    devices.find((device) => {
      if (device.id === id) {
        SelectedDevicesNames.push(device.name);
      }
    });
  });

  return SelectedDevicesNames.map((el, idx) => (
    <React.Fragment key={idx}>
      <b style={shouldBreakLine(el)}>{el}</b>
      {idx + 1 !== SelectedDevicesNames.length ? `, ` : ''}
    </React.Fragment>
  ));
};

export function getPointerPosition(deg = 0, breakpoint = 'full') {
  let bgcW = Constants.locPointerBackgroundWidth;
  let bgcH = Constants.locPointerBackgroundHeight;

  if (breakpoint === 'small') {
    bgcW = Constants.locPointerBackgroundWidthSmall;
    bgcH = Constants.locPointerBackgroundHeightSmall;
  }

  let calculatedTop = 0;
  let calculatedLeft = 0;

  // +7 - it's correction for pointer
  switch(deg) {
    case 0:
      calculatedTop = -1 * (bgcH / 2 + 7)
      calculatedLeft = -1 * (bgcW / 2)
      break;
    case 90:
      calculatedTop = -1 * (bgcH / 2)
      calculatedLeft = -1 * (bgcW / 2 - 7)
      break;
    case 180:
      calculatedTop = -1 * (bgcH / 2 - 7)
      calculatedLeft = -1 * (bgcW / 2)
      break;
    case 270:
      calculatedTop = -1 * (bgcH / 2)
      calculatedLeft = -1 * (bgcW / 2 + 7)
      break;
    default:
      break;
  }

  const top = `${calculatedTop}px`;
  const left = `${calculatedLeft}px`;

  return { top, left };
}

export function getStatOptionsForChart(getAvailableStatOptions, coloursByDeviceIds, devices) {
  return [
    ...Constants.alwaysAvailableStatOptions.map((option) => ({
      code: option,
      display: option.toUpperCase(),
    })),
    ...getAvailableStatOptions(coloursByDeviceIds, devices),
  ];
}

export const getTranslatedMeasurement = (el, t) => {
  return el.display
    ? Constants.textMeasurements.includes(el.code)
      ? t(`measurements:${el.code}`)
      : el.display
    : t(`measurements:${el.code}`);
};
