import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Provider as StoreProvider } from 'react-redux';
import * as Sentry from '@sentry/react';

import router from './app/router';
import Root from './app/Root';
import { store } from './store/configureStore';
import './i18n';

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_REQUIRED, REACT_APP_SENTRY_ENV, REACT_APP_VER } = process.env;

if (REACT_APP_SENTRY_REQUIRED === 'true') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    environment: REACT_APP_SENTRY_ENV,
    release: `atmocube@${REACT_APP_VER}`,
    ignoreErrors: ['Object Not Found Matching Id'],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      // Check if the event is an HTTP error
      if (event.exception) {
        const exception = event.exception.values[0];
        // Check if the status code is 403
        if (exception && exception.value && exception.value.includes('403')) {
          return null;  // Don't send 403 errors to Sentry
        }
      }
      return event;  // Send all other events
    },
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Sentry.ErrorBoundary
    fallback={
      <div style={{ width: '100vw', height: '100vh', textAlign: 'center' }}>
        An error has occurred
      </div>
    }
  >
    <StoreProvider store={store}>
      <Root>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </Root>
    </StoreProvider>
  </Sentry.ErrorBoundary>
);
