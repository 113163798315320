import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { usePermissions } from '@/processes/use-permissions';

const NeedLocationRoute = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  const currentOrganizationId = useSelector((state) => state.currentUser.currentOrganizationId);
  const allLocations = useSelector((state) => state.currentUser.locations);

  const locations = allLocations.filter((loc) => loc.organization === currentOrganizationId);

  const { isCurrentOrgAdmin } = usePermissions();

  let redirect = null;

  if (locations.length === 0) {
    redirect = isCurrentOrgAdmin ? '/locations/add' : '/access_error';
  }

  if (redirect && redirect !== pathname) {
    return <Navigate to={redirect} />
  }

  return children;
};

export default NeedLocationRoute;
