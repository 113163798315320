import React from 'react';
import classnames from 'classnames';
import styles from './BaseTooltip.module.css';

const BaseTooltip = ({ className, children, text, width, disabled }) => {
  const textInlineStyles = { width: width || 'auto' };

  return (
    <div className={classnames(styles.root, disabled && styles.rootDisabled, className)}>
      { children }
      { text && (
        <div className={styles.text} style={textInlineStyles}>
          { text }
        </div>
      )}
    </div>
  );
};

export default BaseTooltip;
