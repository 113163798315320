import { Navigate } from 'react-router-dom';

import { usePermissions } from '@/processes/use-permissions';

const ProtectedRoute = ({ children, restrictedFor = [] }) => {
  const { isViewer, isAnyLocAdmin, isCurrentOrgAdmin, isUserHelpDeskAdmin } = usePermissions();

  let isRestrictedRoute = false;

  for (const userRole of restrictedFor) {
    switch (userRole) {
      case 'ORG_ADMIN':
        isRestrictedRoute = isCurrentOrgAdmin;
        break;
      case 'LOC_ADMIN':
        isRestrictedRoute = isAnyLocAdmin && !isCurrentOrgAdmin;
        break;
      case 'VIEWER':
        isRestrictedRoute = isViewer && !isAnyLocAdmin && !isCurrentOrgAdmin;
        break;
      case 'NOT_HELP_DESK_ADMIN':
        isRestrictedRoute = !isUserHelpDeskAdmin;
        break;
      default:
        break;
    }
    if (isRestrictedRoute) {
      break;
    }
  }

  if (isRestrictedRoute) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default ProtectedRoute;
