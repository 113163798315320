import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { cilMenu, cilBell } from '@coreui/icons';

import useAnalyticsEventTracker from '@/util/hooks/useAnalyticsEventTracker';
import { useNotificationsCount } from '@/features/notifications';
import { BaseBadge } from '@/shared/ui/badge';
import HeaderDropdown from '@/app/ui/HeaderDropdown';
import { ReactComponent as SidebarLogo } from '@/resources/assets/icons/sidebar-logo-r.svg';
import TheBreadcrumbs from './TheBreadcrumbs';

import styles from './TheHeader.module.css';
import { PlanBanner } from '@/features/plans/ui/PlanBanner';

const HeaderBrand = ({ logo, darkMode }) => (
  <NavLink className={styles.brand} to="/" style={{ backgroundImage: `url("${logo}")` }} data-testid={"header-logo"} >
    {!logo && <SidebarLogo style={{ width: '100%' }} fill={darkMode ? '#fff' : '#000'} />}
  </NavLink>
);

const TheHeader = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const organizations = useSelector((state) => state.currentUser.organizations);
  const currentOrgId = useSelector((state) => state.currentUser.currentOrganizationId);
  const breakpoint = useSelector((state) => state.view.breakpoint);
  const darkMode = useSelector((state) => state.view.darkMode);

  const { notificationsCount } = useNotificationsCount(currentOrgId);

  const trackGAEvent = useAnalyticsEventTracker('Top bar');

  const currentOrgLogo = organizations.find((org) => org.id === currentOrgId)?.logo;
  const hasOrg = organizations.length > 0;
  const isBigSizeDevice = breakpoint === 'full' || breakpoint === 'large';

  return (
    <header className={styles.root}>
      <div className={styles.topLine}>
        {isBigSizeDevice ? (
          <TheBreadcrumbs />
        ) : (
          <>
            <CIcon className={styles.burger} icon={cilMenu} size="lg" onClick={toggleSidebar} data-testid={"header-burger"} />
            <HeaderBrand logo={currentOrgLogo} darkMode={darkMode} />
          </>
        )}

        <div className={styles.rightMenu}>
          {hasOrg && <PlanBanner />}
          {hasOrg && (
            <div className={styles.notifications}>
              <CIcon
                className={styles.notificationsIcon}
                icon={cilBell}
                size="lg"
                onClick={() => {
                  trackGAEvent('top_notifications');
                  navigate('/notifications');
                }}
                testId='header-notifications-badge'
              />
              {notificationsCount !== 0 && (
                <BaseBadge
                  color="danger"
                  position="top-right"
                  rounded
                  onClick={(e) => {
                    e.stopPropagation();
                    trackGAEvent('top_notifications');
                    navigate('/notifications');
                  }}
                >
                  {notificationsCount >= 500 ? '500+' : notificationsCount}
                </BaseBadge>
              )}
            </div>
          )}
          <HeaderDropdown />
        </div>
      </div>

      {hasOrg && !isBigSizeDevice && (
        <div className={styles.subheader}>
          <TheBreadcrumbs />
        </div>
      )}
    </header>
  );
};

export default TheHeader;
