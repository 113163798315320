import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from '@/processes/use-permissions';
import BaseButton from '../button/BaseButton';
import BaseTooltip from '../tooltip/BaseTooltip';

import styles from './ActionButton.module.css';

const ActionButton = ({ disabled = false, block = false, children, testId, ...other }) => {
  const { t } = useTranslation(['general']);
  const { isDemoUser } = usePermissions();

  const isDisabled = isDemoUser || disabled;

  return (
    <BaseTooltip
      className={block && styles.rootBlock}
      text={t('general:demo_user_button')}
      disabled={!isDemoUser}
    >
      <BaseButton disabled={isDisabled} block={block} testId={testId} {...other}>
        {children}
      </BaseButton>
    </BaseTooltip>
  );
};

export default ActionButton;
