import * as actionTypes from './actionTypes';
import axios from '../../util/axios-config';
import { showToast } from '@/shared/helpers';
import { handleBackendError } from '../../util/basic';
import { AuthClass } from '../../util/auth';
import i18next from 'i18next';
import useAnalyticsEventTracker from '../../util/hooks/useAnalyticsEventTracker';

// eslint-disable-next-line
const trackGAEvent = useAnalyticsEventTracker('Registration');

// Actions

export const logInRequest = () => ({
  type: actionTypes.authorization.LOG_IN_REQUEST,
});

export const logInSuccess = ({ data: { auth_token, mfa_required, is_verified } }) => ({
  type: actionTypes.authorization.LOG_IN_SUCCESS,
  authToken: auth_token,
  mfaRequired: mfa_required,
  isVerified: is_verified,
});

export const finalLoginSuccess = ({ data: { auth_token, mfa_required, is_verified } }) => ({
  type: actionTypes.authorization.FINAL_LOGIN_SUCCESS,
  authToken: auth_token,
  mfaRequired: mfa_required,
  isVerified: is_verified,
});

export const check2FACodeRequest = () => ({
  type: actionTypes.authorization.CHECK_CODE_REQUEST,
});

export const check2FACodeSuccess = ({ data: { auth_token, is_verified } }) => ({
  type: actionTypes.authorization.CHECK_CODE_SUCCESS,
  authToken: auth_token,
  isVerified: is_verified,
});

export const resendEmailCodeRequest = () => ({
  type: actionTypes.authorization.RESEND_EMAIL_CODE_REQUEST,
});

export const resendEmailCodeSuccess = () => ({
  type: actionTypes.authorization.RESEND_EMAIL_CODE_SUCCESS,
});

export const checkVerificationRequest = () => ({
  type: actionTypes.authorization.CHECK_VERIFICATION_REQUEST,
});

export const checkVerificationSuccess = ({ data: { is_verified } }) => ({
  type: actionTypes.authorization.CHECK_VERIFICATION_SUCCESS,
  isVerified: is_verified,
});

export const resendVerificationRequest = () => ({
  type: actionTypes.authorization.RESEND_VERIFICATION_REQUEST,
});

export const resendVerificationSuccess = () => ({
  type: actionTypes.authorization.RESEND_VERIFICATION_SUCCESS,
});

export const logOut = () => ({
  type: actionTypes.authorization.LOG_OUT,
});

export const verifyGuaranteed = () => ({
  type: actionTypes.authorization.VERIFY_GUARANTEED,
});

export const signUpRequest = () => ({
  type: actionTypes.authorization.SIGN_UP_REQUEST,
});

export const signUpSuccess = ({ data: { auth_token } }) => ({
  type: actionTypes.authorization.SIGN_UP_SUCCESS,
  authToken: auth_token,
});

export const finishInvitationRequest = () => ({
  type: actionTypes.authorization.FINISH_INVITATION_REQUEST,
});

export const finishInvitationSuccess = ({ data: { auth_token, is_verified } }) => ({
  type: actionTypes.authorization.FINISH_INVITATION_SUCCESS,
  authToken: auth_token,
  isVerified: is_verified,
});

export const setAuthorizationError = (authorizationError) => ({
  type: actionTypes.authorization.SET_AUTHORIZATION_ERROR,
  authorizationError,
});

export const clearAuthorizationError = () => ({
  type: actionTypes.authorization.CLEAR_AUTHORIZATION_ERROR,
});

// Dispatchers

// deprecated - use src/features/auth/sign-in/hooks/use-sign-in.js
export const logIn = (email, password, t) => {
  return (dispatch) => {
    dispatch(logInRequest());
    return axios
      .post(
        'auth/token/login/',
        { email, password },
        { headers: { 'Accept-Language': i18next.language } }
      )
      .then((response) => {
        response.data.errors && dispatch(setAuthorizationError(response.data.errors));
        dispatch(logInSuccess(response));
        trackGAEvent('login', 'Successfull log in');
      })
      .catch((e) => handleBackendError(t, dispatch, e));
  };
};

// deprecated - use src/features/auth/2fa/hooks/use-check-2fa-code.js
export const check2FACode = (code, t) => {
  return (dispatch) => {
    dispatch(check2FACodeRequest());
    return axios
      .post('auth/mfa/confirm/email/', { code }, { headers: AuthClass.authHeaders({}, true, true) })
      .then((response) => {
        dispatch(check2FACodeSuccess(response));
        AuthClass.deleteTemporaryToken();
        AuthClass.deleteUserEmail();
      })
      .catch((e) => handleBackendError(t, dispatch, e));
  };
};

export const resendEmailCode = (email, t) => {
  return (dispatch) => {
    dispatch(resendEmailCodeRequest());
    return axios
      .post('auth/mfa/resend/email/', {}, { headers: AuthClass.authHeaders({}, true, true) })
      .then((response) => dispatch(resendEmailCodeSuccess(response)))
      .then(() => showToast(dispatch, t('auth:resendCode'), `${t('auth:mfaCode1')} ${email}`))
      .catch((e) => handleBackendError(t, dispatch, e));
  };
};

export const checkVerification = (t) => {
  return (dispatch) => {
    dispatch(checkVerificationRequest());
    return axios
      .get('auth/users/me/', { headers: AuthClass.authHeaders() })
      .then((response) => dispatch(checkVerificationSuccess(response)))
      .catch((e) => handleBackendError(t, dispatch, e));
  };
};

export const resendVerification = (email, type, current_email, t) => {
  return (dispatch) => {
    dispatch(resendVerificationRequest());
    return axios
      .post(
        'auth/users/resend_activation/',
        { email, type, current_email },
        { headers: AuthClass.authHeaders() }
      )
      .then((response) => dispatch(resendVerificationSuccess(response)))
      .then(() =>
        showToast(
          dispatch,
          t('general:confirmation'),
          `${t('toasts:confirmationEmail1')} ${email} ${t('toasts:confirmationEmail2')}`
        )
      )
      .catch((e) => handleBackendError(t, dispatch, e, ['already_activated']));
  };
};

export const signUp = (validatorRef, firstName, lastName, password, email, t) => {
  return (dispatch) => {
    dispatch(signUpRequest());
    axios
      .post(
        'auth/users/',
        {
          first_name: firstName,
          last_name: lastName,
          password,
          email,
        },
        { headers: { 'Accept-Language': i18next.language } }
      )
      .then((response) => {
        dispatch(signUpSuccess(response));
        trackGAEvent('registration', 'Successfull sign up');
      })
      .then(() =>
        showToast(
          dispatch,
          t('general:confirmation'),
          `${t('toasts:confirmationEmail1')} ${email} ${t('toasts:confirmationEmail2')}`
        )
      )
      .catch((e) => handleBackendError(t, dispatch, e, ['email', 'password'], validatorRef));
  };
};

export const finishInvitation = (
  validatorRef,
  t,
  firstName,
  lastName,
  password,
  email,
  uid,
  token,
  history
) => {
  return (dispatch) => {
    dispatch(finishInvitationRequest());
    axios
      .post(
        'auth/users/finish_invitation/',
        {
          first_name: firstName,
          last_name: lastName,
          password,
          email,
          uid,
          token,
        },
        { headers: { 'Accept-Language': i18next.language } }
      )
      .then((response) => dispatch(finishInvitationSuccess(response)))
      .then(() => history.push('/'))
      .catch((e) => handleBackendError(t, dispatch, e, ['email', 'password'], validatorRef));
  };
};
