export const gray = '#2B2B38';
export const primary = 'var(--primary)';

export const common = {
  dullText: '#999',
};

export const status = {
  invalid: '#EB593A',
  invalidTransparent: 'rgba(235, 89, 58, 0.25)',
  valid: '#8D90FB',
  validTransparent: 'rgba(141, 144, 251, 0.25)',
};

export const themes = {
  dark: {
    commonText: '#e1e1e1',
    pointerBackground: '#a7acb0',
    commonTextDisabled: 'rgba(225, 225, 225, 0.35)',
    inputBG: 'rgba(0, 0, 0, 0.5)',
    inputBorder: 'rgba(255, 255, 255, 0.35)',
    inputFocusBorder: 'rgba(255, 255, 255, 0.2)',
    inputPlaceholder: '#fff',
    accentText: '#18dcdc',
    accentTextContrastColor: '#000',
    accentElement: '#12a3a3',
    accentElementText: '#e1e1e1',
    accentElementBGHover: '#0e8080',
    accentElementBorderHover: '#0d7575',
    popupBG: '#20202a',
    sideNavBG: '#2c2c34',
    plainBG: '#000',
    panelBorder: '#181924',
    panelBG: '#34343b',
    listItemHover: 'rgba(255, 255, 255, 0.1)',
    specialItem: '#20202a',
    selectedItemBG: 'rgba(242, 242, 242, 0.1)',
    cardBG: '#23242d',
    tableSelected: '#45464D',
    tableBorders: 'rgba(255, 255, 255, 0.1)',
    pagination: 'rgba(0, 0, 0, 0.7)',
    paginationDisabledBG: 'rgba(255, 255, 255, 0.025)',
    paginationDisabled: 'rgba(255, 255, 255, 0.5)',
    paginationBorder: '#181924',
    profileEmailCardBorder: '#d75871',
    profileEmailCardHeaderBG: '#20202a',
    modalDialogBG: '#2c2c34',
    modalDialogBorder: '#2c2c34',
    cancelBtnBG: '#7e8082',
    cancelBtnBGHover: '#646768',
    specialElementText: '#e1e1e1',
    icon: 'rgba(255, 255, 255, 0.75)',
    iconHovered: 'rgba(255, 255, 255, 0.9)',
    badgeDark: {
      bg: '#f0f3f5',
      font: '#4f5d73',
    },
    badgeSecondary: {
      bg: '#7e8082',
      font: '#ffffff',
    },
    badgeWarning: {
      bg: '#d75871',
      font: '#ffffff',
    },
    badgeInfo: {
      bg: '#71c2ff',
      font: '#4f5d73',
    },
    badgeNotificationTable: {
      bg: '#12a3a3',
      font: '#FFFFFF',
    },
    datePickerSelection: '#12a3a3',
    datePickerSelectionHover: '#7fc0f1',
    notificationBadge: '#d56750',
    toastBg: '#F3F7F7',
    toastText: '#1B1B1B',
  },

  light: {
    commonText: '#3c4b64',
    commonTextDisabled: 'rgba(60, 75, 100, 0.35)',
    contrastStatusText: 'rgb(138, 147, 162)',
    offlinePoint: 'rgb(204, 204, 204)',
    inputBG: '#fff',
    inputBorder: '#e4e7ea',
    inputFocusBorder: '#8ad4ee',
    inputPlaceholder: '#73818f',
    accentText: '#00B5B5',
    accentTextContrastColor: '#fff',
    accentElement: '#00B5B5',
    accentElementText: '#fff',
    accentElementBGHover: '#008f8f',
    accentElementBorderHover: '#008f8f',
    popupBG: '#F0F3F5',
    sideNavBG: '#3c4b64',
    plainBG: '#fff',
    panelBorder: '#d8dbe0',
    panelBG: '#fff',
    listItemHover: '#ebedef',
    specialItem: '#F0F3F5',
    selectedItemBG: '#f2f2f2',
    cardBG: '#F2F4F9',
    tableSelected: '#F5F5F5',
    tableBorders: '#d8dbe0',
    pagination: '#fff',
    paginationDisabledBG: '#fff',
    paginationDisabled: '#8a93a2',
    paginationBorder: '#c4c9d0',
    profileEmailCardBorder: '#ec4364',
    profileEmailCardHeaderBG: '#fff',
    modalDialogBG: '#fff',
    modalDialogBorder: '#d8dbe0',
    cancelBtnBG: '#648D9B',
    cancelBtnBGHover: '#557884',
    specialElementText: '#4f5d73',
    icon: 'rgba(0, 0, 21, 0.5)',
    iconHovered: 'rgba(0, 0, 21, 0.7)',
    badgeDark: {
      bg: '#2f353f',
      font: '#ffffff',
    },
    badgeSecondary: {
      bg: '#648d9b',
      font: '#ffffff',
    },
    badgeWarning: {
      bg: '#ec4364',
      font: '#ffffff',
    },
    badgeInfo: {
      bg: '#71c2ff',
      font: '#4f5d73',
    },
    badgeNotificationTable: {
      bg: '#12a3a3',
      font: '#FFFFFF',
    },
    datePickerSelection: '#71C2FF',
    datePickerSelectionHover: '#F0F3F5',
    notificationBadge: '#EB593A',
    toastBg: '#1A3838',
    toastText: '#FFF',
  },
};
