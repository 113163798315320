import React from 'react';
import { CDropdown } from '@coreui/react';
import cn from 'classnames';

import styles from './BaseDropdown.module.css';

const BaseDropdown = ({ className, children, testId, ...rest }) => {
  const classnames = [
    styles.root,
    className,
  ];

  return (
    <CDropdown className={cn(classnames)} data-testid={testId} {...rest}>
      {children}
    </CDropdown>
  );
};

export default BaseDropdown;
