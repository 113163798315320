import cn from 'classnames';

import styles from './BaseLoader.module.css';

const BaseLoader = ({ className }) => {
  const classnames = [
    styles.root,
    className,
  ];

  return (
    <div className={cn(classnames)}>
      <div className={styles.spinner} />
    </div>
  );
};

export default BaseLoader;
