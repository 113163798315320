export const COMMANDS_TYPES = {
  startUpdate: 'ota_start',
  checkUpdate: 'ota_check',
}

export const COMMANDS_STATUS = {
  pending: 'pending',
  processing: 'processing',
  completed: 'completed',
  error: 'error',
}
