import React from 'react';
import cn from 'classnames';
import styles from './BaseBadge.module.css';

const BaseBadge = React.forwardRef(({
  className,
  children,
  position = 'static',
  color = 'white',
  rounded,
  height,
  width,
  testId,
  ...other
}, ref) => {
  const classnames = [
    styles.root,
    styles[`position--${position}`],
    styles[`color--${color}`],
    rounded && styles.rounded,
    className,
  ];

  const inlineStyles = () => {
    const styles = {};
    if (height) styles.height = `${height}px`;
    if (width) styles.minWidth = `${width}px`;
    return styles;
  };

  return (
    <div ref={ref} className={cn(classnames)} {...other} style={inlineStyles()} data-testid={testId}>
      {children}
    </div>
  );
});

export default BaseBadge;
