import cn from 'classnames';
import { ring } from 'ldrs';

import styles from './BaseSpinner.module.css';

const BaseSpinner = ({ size = 'large', position='center' }) => {
  ring.register();

  const classnames = [
    styles.root,
    styles[`position--${position}`],
  ];

  const formRingSize = () => {
    switch (size) {
      case 'small':
        return '20';
      case 'medium':
        return '40';
      case 'large':
        return '60';
      default:
        return '20';
    }
  };
  const ringSize = formRingSize();

  return (
    <div className={cn(classnames)}>
      <l-ring
        size={ringSize}
        stroke={ringSize / 10}
        bg-opacity="0.3"
        bg-color="var(--t-panel-bg)"
        speed="2"
        color="var(--t-accent-element)"
      ></l-ring>
    </div>
  );
};

export default BaseSpinner;
