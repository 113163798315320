import { useSelector } from 'react-redux';
import { selectCurrentOrganizationId, selectCurrentUserIsHelpDeskAdmin, selectCurrentUserIsDemo } from '@/store/reducers/currentUser';

export function usePermissions() {
  const userOrganizations = useSelector((state) => state.currentUser.organizations);
  const userLocations = useSelector((state) => state.currentUser.locations);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const currentOrgLocations = userLocations.filter((loc) => loc.organization === currentOrganizationId);

  function checkIsOrgAdmin(organizationId) {
    const organization = userOrganizations.find((org) => org.id === organizationId);

    return organization?.role === 'admin';
  }

  function checkIsLocAdmin(locationId) {
    const location = userLocations.find((loc) => loc.id === locationId);

    return location?.role === 'admin';
  }

  /**
   *
   * @param {Device | Floor} item Entity, which has location
   * @returns {boolean} If true, user is admin for provided item
   */
  function checkIsAdminForItem(item) {
    if (!item) return false;

    const { location: itemLocation } = item;
    if (!itemLocation) {
      throw new Error('Entity does not have location')
    }

    const location = userLocations.find((loc) => loc.id === itemLocation.id);

    return location?.role === 'admin';
  }

  const isUserHelpDeskAdmin = useSelector(selectCurrentUserIsHelpDeskAdmin);
  const isDemoUser = useSelector(selectCurrentUserIsDemo)

  const isCurrentOrgAdmin = checkIsOrgAdmin(currentOrganizationId);
  const isAnyLocAdmin = currentOrgLocations.some((loc) => loc.role === 'admin');
  const hasAdminRights = isCurrentOrgAdmin || isAnyLocAdmin;
  const isViewer = !hasAdminRights;

  return {
    isCurrentOrgAdmin,
    isAnyLocAdmin,
    hasAdminRights,
    isViewer,
    isUserHelpDeskAdmin,
    isDemoUser,
    checkIsOrgAdmin,
    checkIsLocAdmin,
    checkIsAdminForItem,
  };
}
