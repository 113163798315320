import { useState } from 'react';

import { useBackendErrors } from '@/shared/hooks/use-backend-errors';
import {
  getNotifications as apiGetNotifications,
  markNotificationViewed as apiMarkNotificationViewed,
} from '../api';

export function useNotifications(orgId) {
  const [notifications, setNotifications] = useState([]);
  const { errors, getErrors } = useBackendErrors();
  const [fetchingState, setFetchingState] = useState('');

  const transformData = (data) =>
    data.map((item) => ({ ...item.notification, notification: item.notification.type }));

  const getNotifications = async ({ params }) => {
    apiGetNotifications({ orgId, params })
      .then((response) => {
        const notificationsData = transformData(response);
        setNotifications(notificationsData);
      })
      .catch((err) => {
        getErrors(err);
      })
      .finally(() => {
        setFetchingState('');
      });
  };

  const markNotificationsViewed = async () => {
    apiMarkNotificationViewed(orgId).catch((err) => {
      getErrors(err);
    });
  };

  return {
    notifications,
    errors,
    fetchingState,
    getNotifications,
    markNotificationsViewed,
  };
}
