import * as actionCreators from '@/store/actions';

export function showToast(dispatch, header, message, icon = null, hasCloseButton = false, identification = null) {
  dispatch(actionCreators.addToast(header, message, icon, hasCloseButton, identification));
}

export function changeToast(dispatch, header, message, icon = null, hasCloseButton = false, identification = null) {
  dispatch(actionCreators.changeToast(header, message, icon, hasCloseButton, identification));
}

export function deleteToast(dispatch, identification) {
  dispatch(actionCreators.removeToastWithId(identification));
}
