import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useHover } from '@/shared/hooks/use-hover';
import { ReactComponent as SidebarLogo } from '@/resources/assets/icons/sidebar-logo-r.svg';
import { ReactComponent as IconArrow } from '@/resources/assets/icons/arrow-back.svg';
import { cutString } from '@/util/basic';
import { EventTracker } from '@/shared/helpers';

import navConfig from '../helpers/nav-config';

import styles from './TheSidebar.module.css';
import { usePermissions } from '@/processes/use-permissions';

const SidebarBrand = ({ logo }) => (
  <NavLink
    className={styles.brand}
    to="/"
    data-testid="sidebar-logo"
  >
    {logo
      ? <div className={styles.brandLogo} style={{ backgroundImage: `url("${logo}")` }}></div>
      : <SidebarLogo style={{ width: '100%' }} fill="#ffffff" /> }
  </NavLink>
);

const TheSidebar = ({
  isVisible,
  isMinimized,
  toggleSidebar,
  toggleMinimization,
}) => {
  const { t } = useTranslation(['routes']);
  const eventTracker = new EventTracker('Side menu');

  const breakpoint = useSelector((state) => state.view.breakpoint);
  const organizations = useSelector((state) => state.currentUser.organizations);
  const currentOrgId = useSelector((state) => state.currentUser.currentOrganizationId);

  const [hoverRef, isHovering, setIsHovering] = useHover();
  const [isClosing, setIsClosing] = useState(false);

  const classnames = [
    styles.root,
    isVisible && styles.rootVisible,
    isMinimized && styles.rootMinimized,
    isHovering && isMinimized && isVisible && styles.rootExpanded,
    isClosing && styles.rootClosing,
  ];

  const currentOrgLogo = organizations.find((org) => org.id === currentOrgId)?.logo;

  let sidebarTitle;
  if (currentOrgId != null && organizations.length > 0) {
    sidebarTitle = cutString(organizations.find((org) => org.id === currentOrgId)?.name, 15);
    if (isMinimized && !isHovering) {
      sidebarTitle = sidebarTitle[0].toUpperCase();
    }
  }
  const { isCurrentOrgAdmin, isAnyLocAdmin } = usePermissions();

  const protectedNavConfig = navConfig.filter(
    (item) =>
      (!item.adminOnly && !item.locAdminOnly) ||
      (item.adminOnly && isCurrentOrgAdmin) ||
      (item.locAdminOnly && isAnyLocAdmin)
  );

  const translatedNavConfig = protectedNavConfig.map((item) => ({
    ...item,
    text: t(`routes:${item.name}`),
  }));

  const sendAnalytics = (link) => {
    const linkName = link.analyticName || 'menu_' + link.to.replace(/^\//, '');
    eventTracker.track(linkName);
  };

  function onNavItemClick(navItem, needToggle = true) {
    sendAnalytics(navItem);

    if (needToggle && ['small', 'medium'].includes(breakpoint)) {
      toggleSidebar();
    }
  };

  function handleClick() {
    setIsHovering(false);
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
    }, 200);
  }

  function onClickToggleButton() {
    toggleMinimization();
    handleClick();
  }

  return (
    <aside ref={hoverRef} className={cn(classnames)}>
      <nav className={styles.navWrapper}>
        {(breakpoint === 'large' || breakpoint === 'full') && (
          <SidebarBrand logo={currentOrgLogo} />
        )}
        <ul className={styles.navList}>
          {currentOrgId != null && (
            <li className={styles.navItem}>
              <strong className={styles.title}>{sidebarTitle}</strong>
            </li>
          )}
          {translatedNavConfig.map((navItem) => {
            const { to, icon, name, text, external } = navItem;

            return (
              <li
                key={name}
                className={styles.navItem}
              >
                {external ? (
                  <Link
                    className={styles.navLink}
                    to={to}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => onNavItemClick(navItem)}
                    data-testid={`sidebar-link-${name}`}
                  >
                    {icon(styles.navIcon)}
                    <span>{text}</span>
                  </Link>
                ) : (
                  <NavLink
                    className={styles.navLink}
                    to={to}
                    onClick={() => onNavItemClick(navItem)}
                    data-testid={`sidebar-link-${name}`}
                  >
                    {icon(styles.navIcon)}
                    <span>{text}</span>
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
        {(breakpoint === 'large' || breakpoint === 'full') && (
          <button className={styles.toggler} onClick={onClickToggleButton} data-testid='sidebar-toggler'>
            <IconArrow className={styles.togglerArrow} />
          </button>
        )}
      </nav>
      <div className={styles.backdrop} onClick={toggleSidebar} data-testid='sidebar-backdrop'></div>
    </aside>
  );
};

export default React.memo(TheSidebar);
