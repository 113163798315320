import i18next from 'i18next';

export class AuthClass {
  static isLoggedIn() {
    return localStorage.getItem('token') != null;
  }

  static setUserEmail(email) {
    localStorage.setItem('email', email);
  }

  static getUserEmail() {
    return localStorage.getItem('email');
  }

  static deleteUserEmail() {
    localStorage.removeItem('email');
  }

  static saveCredentials(token, tokenName = 'token') {
    localStorage.setItem(tokenName, token);
  }

  static checkTempToken() {
    return localStorage.getItem('temporaryToken') ? true : false;
  }

  static checkToken() {
    return localStorage.getItem('token') ? true : false;
  }

  static signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('dashboardLocation');
    localStorage.removeItem('dashboardFloor');
    localStorage.removeItem('dashboardStatus');

    this.deleteTemporaryToken();
    this.deleteUserEmail();
  }

  static deleteTemporaryToken() {
    localStorage.removeItem('temporaryToken');
  }

  static authHeaders(headers = {}, withLang = true, isTemporary = false) {
    if (isTemporary) {
      headers['Authorization'] = 'Token ' + localStorage.getItem('temporaryToken');
      if (withLang) {
        headers['Accept-Language'] = i18next.language;
      }
      return headers;
    }

    headers['Authorization'] = 'Token ' + localStorage.getItem('token');
    if (withLang) {
      headers['Accept-Language'] = i18next.language;
    }
    return headers;
  }

  static getToken() {
    return localStorage.getItem('token');
  }
}
