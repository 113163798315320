import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectCurrentOrganization,
  selectCurrentUserId,
} from '@/store/reducers/currentUser';
import { usePermissions } from '@/processes/use-permissions';
import { BaseBadge } from '@/shared/ui/badge';
import { usePopup } from '@/shared/hooks/use-popup';

import PlanPopup from './PlanPopup';

import styles from './PlanBanner.module.css';

export const PlanBanner = () => {
  const { t } = useTranslation(['plans']);

  const {
    refs,
    floatingStyles,
    setIsOpen,
    isMounted,
    transitionClass,
  } = usePopup({ placement: 'bottom', offset: 10, transitioned: true });

  const { hasAdminRights } = usePermissions();
  const userId = useSelector(selectCurrentUserId);
  const currentOrganization = useSelector(selectCurrentOrganization);

  const plan = currentOrganization?.plan;
  const isOwner = userId === currentOrganization?.owner;
  const isPlanDefault = plan?.default;

  const bannerText = isPlanDefault
    ? t('plans:free_plan_duration')
    : `${plan?.description?.name?.toUpperCase()} ${currentOrganization?.organization_devices_count}/${
        currentOrganization?.plan_licenses
      }`;

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    plan &&
    hasAdminRights &&
    currentOrganization ? (
      <div className={styles.planBannerContainer}>
        <BaseBadge
          ref={refs.setReference}
          color={isPlanDefault ? 'attention' : 'ghost'}
          height={23}
          width={100}
          className={styles.planBanner}
          onClick={togglePopup}
          testId='plan-banner'
        >
          {bannerText}
        </BaseBadge>
        {isMounted && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={styles.popupContainer}
          >
            <div className={transitionClass}>
              <PlanPopup
                isPlanDefault={isPlanDefault}
                isOwner={isOwner}
                plan={plan}
                currentOrganization={currentOrganization}
                togglePopup={togglePopup}
              />
            </div>
          </div>
        )}
      </div>
    ) : null
  );
};
